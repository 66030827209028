import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sidebarShow: true,
  sidebarUnfoldable: false,
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setSidebarShow: (state, action) => {
      state.sidebarShow = action.payload;
    },
    setSidebarUnfoldable: (state, action) => {
      state.sidebarUnfoldable = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSidebarShow, setSidebarUnfoldable } = sidebarSlice.actions;

export const sidebarSelector = state => state.sidebar;

export default sidebarSlice.reducer;
